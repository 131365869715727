import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Hi`}</p>
    <p>{`I'm Emil. I'm have been an Internet nerd for most of my life. This website is mostly because you need to have `}<strong parentName="p">{`something`}</strong>{` on your domain.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      